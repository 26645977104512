<template>
	<router-link to="/profile">
		<div class="stat__item user">
			<div class="prepend">
				<img v-if="avatarUrl" :src="avatarUrl" alt="avatar" class="avatar" />
				<img v-else src="../../assets/defaultAvatar.png" alt="avatar" class="avatar" />
			</div>
			<div class="labelDataWrapper">
				<div class="labelTinyAppendWrapper">
					<span class="label">{{ $t('dashboard.logged-in-as') }}</span>
				</div>
				<div class="dataWrapper">
					<span class="data">{{ username || '---' }}</span>
				</div>
			</div>
		</div>
	</router-link>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'UserStat',
	computed: {
		...mapGetters({
			user: 'getUser',
			username: 'getUsername',
		}),
		avatarUrl() {
			return this.user?.avatarUrl;
		},
	},
};
</script>

<style scoped lang="scss">
a {
	flex: 1;
	min-width: max-content;
}

.stat__item {
	display: flex;
	padding: 0.5rem 0.8rem;
	align-items: center;
	background: rgba(42, 46, 49, 0.5);
	border: 1px solid #2a2e31;
	border-radius: 6px;
	min-width: max-content;
	height: 60px;

	.prepend {
		height: 100%;
		margin-right: 0.8rem;

		img {
			height: 100%;
		}
	}

	.labelTinyAppendWrapper {
		display: flex;
	}

	.labelDataWrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.dataWrapper {
			display: flex;
		}
	}

	span.label {
		font-size: 12px;
		color: rgba(255, 255, 255, 0.5);
		text-align: start;
	}

	span.data {
		font-size: 16px;
		font-weight: bold;
		color: white;
		text-align: start;
	}
}

.avatar {
	border-radius: 50%;
}

@media screen and (max-width: 359px) {
	.stat__item {
		.prepend {
			display: none;
		}
	}
}
</style>
