<template>
	<a class="logout" @click="handleLogout">
		<div class="logout__button">
			<img src="../../assets/logout.svg" alt="eye" />
		</div>
	</a>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	name: 'LogoutButton',
	methods: {
		...mapActions(['logout']),
		handleLogout(e) {
			e.preventDefault();
			this.logout({
				redirectToCurrent: this.$route.meta?.redirectBackAfterLogout === true,
			});
		},
	},
};
</script>

<style scoped lang="scss">
@import 'src/assets/style/variables';
@import 'src/assets/style/button';

.logout {
	&__button {
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid rgba(255, 255, 255, 0.1);
		border-radius: 6px;
		width: 60px;
		height: 60px;
		background-size: 120% auto;
		background-image: linear-gradient(134.22deg, $abbey-gray -63.52%, $shark-black 74.66%);

		&:hover {
			@include button-hover;
		}
	}
}
</style>
