<template>
	<PageLayout :title="$t('identity-verification.title')" :subtitle="$t('identity-verification.subtitle')">
		<div v-if="tokenLoading || sumSubLoading" class="loading-wrapper">
			<Loader />
		</div>

		<h2 v-if="tokenError" class="error">
			{{ tokenError }}
		</h2>
		<SumSub v-if="accessToken" :access-token="accessToken" @verified="handleVerified" @loaded="handleLoaded" />
		<CButton v-if="showContinueButton" primary @click="handleContinue">
			{{ $t('global.continue') }}
		</CButton>
	</PageLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import apiClient from '@/api';
import CButton from '@/shared/cbutton/CButton';
import PageLayout from '@/components/page-layout/PageLayout';
import Loader from '@/shared/loader/Loader';
import SumSub from '@/components/sumsub/SumSub';

export default {
	name: 'IdentityVerification',
	components: {
		Loader,
		PageLayout,
		CButton,
		SumSub,
	},
	data() {
		return {
			showContinueButton: false,
			tokenLoading: true,
			sumSubLoading: false,
			accessToken: null,
			tokenError: null,
		};
	},
	mounted() {
		this.getSumSubToken();
	},
	computed: {
		...mapGetters(['getUser']),
	},
	methods: {
		async getSumSubToken() {
			try {
				this.tokenLoading = true;
				const accessToken = await apiClient.getSumSubTokenForUser();
				this.accessToken = accessToken;
			} catch (e) {
				this.tokenError = this.$t('errors.network-error');
			} finally {
				this.tokenLoading = false;
			}
		},
		handleLoaded() {
			this.sumSubLoading = false;
		},
		handleVerified() {
			this.showContinueButton = true;
		},
		handleContinue() {
			if (this.$route.query.redirect) {
				if (this.$route.query.redirect.startsWith('http')) {
					window.location = this.$route.query.redirect;
				} else {
					this.$router.replace(this.$route.query.redirect);
				}
			} else {
				this.$router.replace('/');
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.loading-wrapper {
	margin-top: 4rem;
}

.error {
	text-align: center;
}
</style>
