<template>
	<div>
		<div v-if="width" :style="{ width: `var(--spacer-${size})` }" />
		<div v-if="height" :style="{ height: `var(--spacer-${size})` }" />
	</div>
</template>

<script>
export default {
	name: 'Spacer',
	props: {
		width: {
			type: Boolean,
			default: false,
		},
		height: {
			type: Boolean,
			default: false,
		},
		size: {
			type: String,
			default: 'm',
		},
	},
};
</script>
