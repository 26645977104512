export const postParentMessage = (message) => {
	if (window.ReactNativeWebView) {
		let data = message;
		if (typeof data !== 'string') {
			data = JSON.stringify(data);
		}
		window.ReactNativeWebView.postMessage(data);
	} else {
		window.parent.postMessage(message, '*');
	}
};

export const isInIframe = () => {
	try {
		return window.self !== window.top;
	} catch (e) {
		return true;
	}
};
