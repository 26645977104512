import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import { isInIframe } from '@/utils/embedding';
import AvailablePaymentMethods from './module/available-payment-methods';
import User from './module/user';
import Auth from './module/auth';
import Settings from './module/settings';
import Events from './module/events';

const vuexLocalStorage = new VuexPersistence({
	storage: window.localStorage,
	modules: ['Auth', 'Settings', 'Events'],
});

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		isEmbedded: false,
	},
	mutations: {
		setIsEmbedded: (state, isEmbedded) => {
			state.isEmbedded = isEmbedded;
		},
	},
	actions: {
		async setIsEmbedded({ commit, state }, isEmbedded) {
			if (state.isEmbedded !== isEmbedded) {
				commit('setIsEmbedded', isEmbedded);
			}
		},
	},
	getters: {
		isEmbedded: (state) => state.isEmbedded || isInIframe(),
	},
	modules: {
		AvailablePaymentMethods,
		User,
		Auth,
		Settings,
		Events,
	},
	plugins: [vuexLocalStorage.plugin],
});
