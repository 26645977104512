import Vue from 'vue';
import VueRouter, { isNavigationFailure } from 'vue-router';
import Vuex from 'vuex';
import VueI18n from 'vue-i18n';
import * as Sentry from '@sentry/vue';
import posthog from 'posthog-js';
import './mixins/Environment';
import VueAnalytics from 'vue-analytics';
import { languages, defaultLocale } from '@/i18n';
import IdentityVerification from '@/components/identity-verification/IdentityVerification';
import OAuthAuthorize from '@/components/oauth-authorize/OAuthAuthorize';
import { getPathForNextOutstandingOnboardingStep } from '@/utils/onboardingFlow';
import PosthogUtil from '@/utils/posthog';
import apiClient from './api';
import store from './store/store';
import App from './App.vue';
import VueGtm from '@gtm-support/vue2-gtm';
import Hotjar from 'vue-hotjar';
import Toast from 'vue-toastification';
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';
import 'vue-tel-input/dist/vue-tel-input.css';

// loads components as async component and with a code split point
const Registration = () => import(/* webpackChunkName: "registration" */ '@/components/registration/Registration');

const EmailConfirmation = () =>
	import(/* webpackChunkName: "email-confirmation" */ '@/components/email-confirmation/EmailConfirmation');

const ConfirmPhone = () => import(/* webpackChunkName: "confirm-phone" */ '@/components/confirm-phone/ConfirmPhone');

const PhoneNumberConfirmation = () =>
	import(
		/* webpackChunkName: "phone-confirmation" */ '@/components/confirm-phone/send-verification-code/SendVerificationCode'
	);

const SMSCodeVerification = () =>
	import(/* webpackChunkName: "sms-code-verification" */ '@/components/confirm-phone/verify-sms-code/VerifySMSCode');

const AccountStatement = () =>
	import(/* webpackChunkName: "account-statement" */ '@/components/account-statement/AccountStatement');

const Login = () => import(/* webpackChunkName: "registration" */ '@/components/login/Login');

const LogoutScreen = () => import(/* webpackChunkName: "logout" */ '@/components/logout/LogoutScreen.vue');

const ConfirmAccount = () =>
	import(/* webpackChunkName: "confirm-account" */ '@/components/confirm-account/ConfirmAccount');

const Payments = () => import(/* webpackChunkName: "payments" */ '@/components/payments/Payments');

const Deposit = () => import(/* webpackChunkName: "deposit" */ '@/components/payments/deposit/Deposit');

const NoDeposit = () => import(/* webpackChunkName: "no-deposit" */ '@/components/payments/deposit/NoDepositPage');

const BeforeDepositForm = () =>
	import(/* webpackChunkName: "before-deposit-form" */ '@/components/payments/deposit/BeforeDepositForm');

const DepositSelect = () =>
	import(/* webpackChunkName: "deposit-select" */ '@/components/payments/deposit/DepositSelect');

const DepositMpesa = () =>
	import(/* webpackChunkName: "deposit-mpesa" */ '@/components/payments/deposit/payment-methods/Mpesa');

const DepositFlutterwaveGeneric = () =>
	import(
		/* webpackChunkName: "deposit-flutterwave-generic" */ '@/components/payments/deposit/payment-methods/FlutterwaveGeneric'
	);

const DusupayMpesa = () =>
	import(/* webpackChunkName: "deposit-dusupay-mpesa" */ '@/components/payments/deposit/payment-methods/DusupayMpesa');

const DusupayGeneric = () =>
	import(
		/* webpackChunkName: "deposit-dusupay-generic" */ '@/components/payments/deposit/payment-methods/DusupayGeneric'
	);

const DirectaCreditCard = () =>
	import(
		/* webpackChunkName: "deposit-directa-cc" */ '@/components/payments/deposit/payment-methods/DirectaCreditCard'
	);

const BridgerpayCreditCard = () =>
	import(
		/* webpackChunkName: "deposit-bridger-cc" */ '@/components/payments/deposit/payment-methods/BridgerpayCreditCard'
	);

const Skrill = () =>
	import(/* webpackChunkName: "deposit-skrill" */ '@/components/payments/deposit/payment-methods/Skrill');

const Neteller = () =>
	import(/* webpackChunkName: "deposit-skrill" */ '@/components/payments/deposit/payment-methods/Neteller');

const Paysafecard = () =>
	import(/* webpackChunkName: "deposit-paysafecard" */ '@/components/payments/deposit/payment-methods/Paysafecard');
const Sticpay = () =>
	import(/* webpackChunkName: "deposit-skrill" */ '@/components/payments/deposit/payment-methods/Sticpay');

const PayasiaVnd = () =>
	import(/* webpackChunkName: "deposit-payasia" */ '@/components/payments/deposit/payment-methods/PayasiaVnd');

const PayasiaMyr = () =>
	import(/* webpackChunkName: "deposit-payasia" */ '@/components/payments/deposit/payment-methods/PayasiaMyr');

const PayasiaThb = () =>
	import(/* webpackChunkName: "deposit-payasia" */ '@/components/payments/deposit/payment-methods/PayasiaThb');

const PayasiaIdr = () =>
	import(/* webpackChunkName: "deposit-payasia" */ '@/components/payments/deposit/payment-methods/PayasiaIdr');

const Fasapay = () =>
	import(/* webpackChunkName: "deposit-fasapay" */ '@/components/payments/deposit/payment-methods/Fasapay');

const Paymaxis = () =>
	import(/* webpackChunkName: "deposit-paymaxis" */ '@/components/payments/deposit/payment-methods/Paymaxis');

const Match2Pay = () =>
	import(/* webpackChunkName: "deposit-match2pay" */ '@/components/payments/deposit/payment-methods/Match2Pay');

const Propay = () =>
	import(/* webpackChunkName: "deposit-propay" */ '@/components/payments/deposit/payment-methods/Propay');

const OnlineNaira = () =>
	import(/* webpackChunkName: "deposit-onlinenaira" */ '@/components/payments/deposit/payment-methods/OnlineNaira');

const DirectaOxxo = () =>
	import(/* webpackChunkName: "deposit-directa-oxxo" */ '@/components/payments/deposit/payment-methods/DirectaOxxo');

const PagoEffectivo = () =>
	import(
		/* webpackChunkName: "deposit-directa-pagoeffectivo" */ '@/components/payments/deposit/payment-methods/PagoEffectivo'
	);

const Boleto = () =>
	import(
		/* webpackChunkName: "deposit-directa-boleto" */ '@/components/payments/deposit/payment-methods/DirectaBoleto'
	);

const Coinspaid = () =>
	import(/* webpackChunkName: "deposit-coinspaid" */ '@/components/payments/deposit/payment-methods/Coinspaid');

const Bitstamp = () =>
	import(/* webpackChunkName: "deposit-bitstamp" */ '@/components/payments/deposit/payment-methods/Bitstamp');

const Trustology = () =>
	import(/* webpackChunkName: "deposit-directa-cc" */ '@/components/payments/deposit/payment-methods/Trustology');

const GameChangers = () =>
	import(
		/* webpackChunkName: "deposit-gamechangers-cc" */ '@/components/payments/deposit/payment-methods/GameChangers'
	);

const Gateway = () =>
	import(/* webpackChunkName: "deposit-gateway" */ '@/components/payments/deposit/payment-methods/Gateway');

const PerfectMoney = () =>
	import(/* webpackChunkName: "deposit-perfectmoney" */ '@/components/payments/deposit/payment-methods/PerfectMoney');

const ExternalLinkDeposit = () =>
	import(/* webpackChunkName: "deposite-external-link" */ '@/components/payments/deposit/payment-methods/ExternalLink');

const ExternalLinkWithdrawal = () =>
	import(
		/* webpackChunkName: "deposite-external-link" */ '@/components/payments/withdrawal/payment-methods/ExternalLink'
	);

const AdvancedCash = () =>
	import(/* webpackChunkName: "deposit-advancedcash" */ '@/components/payments/deposit/payment-methods/AdvancedCash');

const Paypound = () =>
	import(/* webpackChunkName: "deposit-paypound" */ '@/components/payments/deposit/payment-methods/Paypound');

const Withdrawal = () => import(/* webpackChunkName: "withdrawal" */ '@/components/payments/withdrawal/Withdrawal');

const BeforeWithdrawalForm = () =>
	import(/* webpackChunkName: "before-withdrawal-form" */ '@/components/payments/withdrawal/BeforeWithdrawalForm');

const WithdrawalSelect = () =>
	import(/* webpackChunkName: "withdrawal-select" */ '@/components/payments/withdrawal/WithdrawalSelect');

const WithdrawalOther = () =>
	import(/* webpackChunkName: "withdrawal-other" */ '@/components/payments/withdrawal/payment-methods/Other');

const WithdrawalBitstamp = () =>
	import(/* webpackChunkName: "withdrawal-bitstamp" */ '@/components/payments/withdrawal/payment-methods/Bitstamp');

const WithdrawalTrustology = () =>
	import(/* webpackChunkName: "withdrawal-trustology" */ '@/components/payments/withdrawal/payment-methods/Trustology');

const KYCDocuments = () =>
	import(/* webpackChunkName: "kyc-documents" */ '@/components/payments/withdrawal/KYCDocuments');

const KYCNotVerified = () =>
	import(/* webpackChunkName: "kyc-documents-unverified" */ '@/components/payments/withdrawal/KYCNotVerified');

const DepositConfirmation = () =>
	import(/* webpackChunkName: "DepositConfirmation" */ '@/components/confirmation/DepositConfirmation');

const MonopolyMoneyConfirmation = () =>
	import(/* webpackChunkName: "DepositConfirmation" */ '@/components/confirmation/MonopolyMoneyConfirmation');

const WithdrawalConfirmation = () =>
	import(/* webpackChunkName: "WithdrawalConfirmation" */ '@/components/confirmation/WithdrawalConfirmation');

const ForgotPassword = () =>
	import(/* webpackChunkName: "forgot-password" */ '@/components/forgot-password/ForgotPassword');

const ForgotPasswordConfirmation = () =>
	import(/* webpackChunkName: "forgot-password-confirmation" */ '@/components/confirmation/ForgotPasswordConfirmation');

const ResetPassword = () =>
	import(/* webpackChunkName: "reset-password" */ '@/components/forgot-password/ResetPassword');

const ProfileInfo = () => import(/* webpackChunkName: "profile-info" */ '@/components/profile-info/ProfileInfo');

const ChangePassword = () =>
	import(/* webpackChunkName: "change-password" */ '@/components/change-password/ChangePassword');

const NotFound = () => import(/* webpackChunkName: "not-found" */ '@/components/NotFound');

const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/components/dashboard/Dashboard');

const KYCUploadConfirmation = () =>
	import(/* webpackChunkName: "kyc-upload-confirmation" */ '@/components/confirmation/KYCUploadConfirmation');

const CreateWallet = () => import(/* webpackChunkName "CreateWallet") */ '@/components/create-wallet/CreateWallet');

const WalletCreated = () => import(/* webpackChunkName "CreateWallet") */ '@/components/create-wallet/WalletCreated');

const Transfer = () => import(/* webpackChunkName: "transfer" */ '@/components/transfer/Transfer');

const StartCode = () => import(/* webpackChunkName: "StartCode" */ '@/components/start-code/InsertStartCode');

const Disclaimer = () => import(/* webpackChunkName: "Disclaimer" */ '@/components/disclaimer/Disclaimer');

const Gate2Pay = () => (window.location.href = 'https://gatetopay.com/gateway');

const MonopolyMoney = () =>
	import(/* webpackChunkName: "deposit-propay" */ '@/components/payments/deposit/payment-methods/MonopolyMoney');

if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
	const sentryIntegrations = [];
	if (process.env.VUE_APP_POSTHOG_KEY && process.env.VUE_APP_POSTHOG_HOST) {
		PosthogUtil.init(process.env.VUE_APP_POSTHOG_KEY, { api_host: process.env.VUE_APP_POSTHOG_HOST });
		sentryIntegrations.push(
			new posthog.SentryIntegration(
				posthog,
				process.env.VUE_APP_SENTRY_ORGANIZATION,
				process.env.VUE_APP_SENTRY_PROJECT_ID
			)
		);
	}

	Sentry.init({
		Vue,
		dsn: process.env.VUE_APP_SENTRY_DSN,
		integrations: sentryIntegrations,
		release: process.env.VUE_APP_SENTRY_RELEASE,
		logError: true,
		beforeSend: (event, hint) => {
			const error = hint.originalException;

			// Do not send navigation failures to Sentry
			// (discussion e.g. here https://stackoverflow.com/questions/62462276/how-to-solve-avoided-redundant-navigation-to-current-location-error-in-vue)
			if (isNavigationFailure(error)) {
				return null;
			}

			return event;
		},
	});

	Sentry.configureScope((scope) => {
		scope.setExtra('state', store.state);
	});
}

Vue.config.productionTip = false;

Vue.use(VueI18n);
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(Toast, {
	transition: 'Vue-Toastification__bounce',
	maxToasts: 20,
	newestOnTop: true,
	position: 'top-center',
	timeout: 5000,
	closeOnClick: true,
	pauseOnFocusLoss: true,
	pauseOnHover: true,
	draggable: true,
	draggablePercent: 0.6,
	showCloseButtonOnHover: false,
	closeButton: 'button',
	icon: true,
	rtl: false,
});

const messages = Object.assign(languages);

Vue.config.productionTip = false;

const i18n = new VueI18n({
	locale: store.getters.getUserLanguage,
	fallbackLocale: defaultLocale,
	messages,
});

export const routes = [
	{
		path: '/',
		name: 'dashboard',
		component: Dashboard,
		meta: { requiresAuth: true },
	},
	{
		path: '/signup',
		name: 'registration',
		component: Registration,
		meta: { requiresAuth: false, useFloatingHeader: true, notAuthenticatedOnly: true },
	},
	{
		path: '/confirm-email',
		name: 'email-confirmation',
		component: EmailConfirmation,
		meta: { requiresAuth: true, useFloatingHeader: true },
	},
	{
		path: '/disclaimer',
		name: 'disclaimer',
		component: Disclaimer,
		meta: { requiresAuth: true },
	},
	{
		path: '/confirm-phone',
		name: 'confirm-phone',
		component: ConfirmPhone,
		meta: { requiresAuth: true },
		children: [
			{
				path: '/',
				name: 'phone-number-confirmation',
				component: PhoneNumberConfirmation,
				meta: { requiresAuth: true },
			},
			{
				path: 'verify',
				name: 'sms-code-verification',
				component: SMSCodeVerification,
				meta: { requiresAuth: true },
			},
		],
	},
	{
		path: '/account-statement',
		name: 'account-statement',
		component: AccountStatement,
		meta: { requiresAuth: true },
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
		meta: { requiresAuth: false, useFloatingHeader: true, notAuthenticatedOnly: true },
	},
	{
		path: '/logout',
		name: 'logout',
		component: LogoutScreen,
		meta: { requiresAuth: false, useFloatingHeader: true },
	},
	{
		path: '/forgot-password',
		name: 'forgot-password',
		component: ForgotPassword,
		props: true,
		meta: { requiresAuth: false, useFloatingHeader: true },
	},
	{
		path: '/forgot-password/confirmation',
		name: 'forgot-password-confirmation',
		component: ForgotPasswordConfirmation,
		meta: { requiresAuth: false, useFloatingHeader: false },
	},
	{
		path: '/verify/:token',
		name: 'confirm-account',
		component: ConfirmAccount,
		meta: { requiresAuth: false },
	},
	{
		path: '/go',
		name: 'signup-code',
		component: StartCode,
		meta: { requiresAuth: false },
	},
	{
		path: '/payments',
		component: Payments,
		meta: { requiresAuth: true },
		children: [
			{
				path: 'deposit/form',
				name: 'before-deposit-form',
				component: BeforeDepositForm,
				meta: { stage: null },
			},
			{
				path: 'deposit/:walletId',
				component: Deposit,
				meta: { stage: 'BeforeDeposit' },
				children: [
					{
						path: '/',
						name: 'deposit-select',
						component: DepositSelect,
					},
					{
						path: 'no_deposit',
						name: 'no-deposit',
						component: NoDeposit,
					},
					{
						path: 'mpesa',
						name: 'deposit-dusupay-mpesa',
						component: DepositMpesa,
					},
					{
						path: 'dp_bank_ng',
						name: 'deposit-dp-bank-ng',
						component: DusupayGeneric,
						props: {
							methodId: 'dp_bank_ng',
							method: 'BANK',
							providerId: 'bank_ng',
							logo: 'bank-transfer.svg',
						},
					},
					{
						path: 'dp_card_ng',
						name: 'deposit-dp-card-ng',
						component: DusupayGeneric,
						props: {
							methodId: 'dp_card_ng',
							method: 'CARD',
							providerId: 'international_usd',
							logo: 'cards.svg',
						},
					},
					{
						path: 'flutterwave',
						name: 'deposit-flutterwave-generic',
						component: DepositFlutterwaveGeneric,
					},
					{
						path: 'onlinenaira',
						name: 'onine-naira',
						component: OnlineNaira,
					},
					{
						path: 'confirmation',
						name: 'deposit-confirmation',
						component: DepositConfirmation,
						props: true,
					},
					{
						path: 'monopolymoney_confirmation',
						name: 'monopolymoney-confirmation',
						component: MonopolyMoneyConfirmation,
						props: (route) => ({ amount: Number(route.query.amount), transactionId: route.query.transactionId }),
					},
					// These are neccessary because perfectmoney will override query parameters before redirecting
					{
						path: 'confirmation/perfectmoney_success',
						redirect: {
							path: '/payments/deposit/confirmation',
							query: { method: 'generic', status: 'success' },
						},
					},
					{
						path: 'confirmation/perfectmoney_failed',
						redirect: {
							path: '/payments/deposit/confirmation',
							query: { method: 'generic', status: 'error' },
						},
					},
					{
						path: 'sticpay_success',
						redirect: {
							path: '/payments/deposit/confirmation',
							query: { method: 'generic', status: 'success' },
						},
					},
					{
						path: 'sticpay_failed',
						redirect: {
							path: '/payments/deposit/confirmation',
							query: { method: 'generic', status: 'error' },
						},
					},
					{
						path: 'confirmation/advancedmoney_success',
						redirect: {
							path: '/payments/deposit/confirmation',
							query: { method: 'generic', status: 'success' },
						},
					},
					{
						path: 'confirmation/advancedmoney_failed',
						redirect: {
							path: '/payments/deposit/confirmation',
							query: { method: 'generic', status: 'error' },
						},
					},
					{
						path: 'confirmation/advancedmoney_status',
						redirect: {
							path: '/payments/deposit/confirmation',
							query: { method: 'generic', status: 'error' },
						},
					},
					{
						path: 'confirmation/neteller_failed',
						redirect: {
							path: '/payments/deposit/confirmation',
							query: { method: 'generic', status: 'error' },
						},
					},
					{
						path: 'confirmation/neteller_success',
						redirect: {
							path: '/payments/deposit/confirmation',
							query: { method: 'generic', status: 'success' },
						},
					},
					{
						path: 'confirmation/payasia_success',
						redirect: {
							path: '/payments/deposit/confirmation',
							query: { method: 'generic', status: 'success' },
						},
					},
					{
						path: 'confirmation/payasia_failed',
						redirect: {
							path: '/payments/deposit/confirmation',
							query: { method: 'generic', status: 'error' },
						},
					},
					{
						path: 'advancedcash',
						name: 'advancedcash',
						component: AdvancedCash,
					},
					{
						path: 'paypound',
						name: 'paypound',
						component: Paypound,
					},
					{
						path: 'monopolymoney',
						name: 'monopoly-money',
						component: MonopolyMoney,
					},
					{
						path: 'dusupay_mpesa',
						name: 'deposit-dp-mpesa',
						component: DusupayMpesa,
					},
					{
						path: 'cards',
						name: 'deposit-dir-cc',
						component: DirectaCreditCard,
					},
					{
						path: 'brcards',
						name: 'deposit-bridger-cc',
						component: BridgerpayCreditCard,
					},
					{
						path: 'skrill',
						name: 'deposit-skrill',
						component: Skrill,
					},
					{
						path: 'neteller',
						name: 'deposit-neteller',
						component: Neteller,
					},
					{
						path: 'sticpay',
						name: 'deposit-sticpay',
						component: Sticpay,
					},
					{
						path: 'paysafecard',
						name: 'deposit-paysafecard',
						component: Paysafecard,
					},
					{
						path: 'payasia_vnd',
						name: 'deposit-payasia-vnd',
						component: PayasiaVnd,
					},
					{
						path: 'payasia_myr',
						name: 'deposit-payasia-myr',
						component: PayasiaMyr,
					},
					{
						path: 'payasia_thb',
						name: 'deposit-payasia-thb',
						component: PayasiaThb,
					},
					{
						path: 'payasia_idr',
						name: 'deposit-payasia-idr',
						component: PayasiaIdr,
					},
					{
						path: 'propay',
						name: 'deposit-propay',
						component: Propay,
					},
					{
						path: 'fasapay',
						name: 'deposit-fasapay',
						component: Fasapay,
					},
					{
						path: 'paymaxis',
						name: 'deposit-paymaxis',
						component: Paymaxis,
					},
					{
						path: 'match2pay',
						name: 'deposit-match2pay',
						component: Match2Pay,
					},
					{
						path: 'gate2pay',
						name: 'deposit-gate2pay',
						component: Gate2Pay,
					},
					{
						path: 'oxxo',
						name: 'deposit-dir-oxxo',
						component: DirectaOxxo,
					},
					{
						path: 'dir_pagoefectivo',
						name: 'deposit-dir-pagoefectivo',
						component: PagoEffectivo,
					},
					{
						path: 'boleto',
						name: 'deposit-dir-boleto',
						component: Boleto,
					},
					{
						path: 'coinspaid',
						name: 'deposit-coinspaid',
						component: Coinspaid,
					},
					{
						path: 'bitstamp',
						name: 'deposit-bitstamp',
						component: Bitstamp,
					},
					{
						path: 'trustology',
						name: 'deposit-trustology',
						component: Trustology,
					},
					{
						path: 'gamechangers',
						name: 'deposit-gamechangers',
						component: GameChangers,
					},
					{
						path: 'gateway',
						name: 'deposit-gateway',
						component: Gateway,
					},
					{
						path: 'perfectmoney',
						name: 'deposit-perfectmoney',
						component: PerfectMoney,
					},
					{
						path: 'external_link',
						name: 'deposit-external_link',
						component: ExternalLinkDeposit,
					},
				],
			},
			{
				path: 'withdrawal/form',
				name: 'before-withdrawal-form',
				component: BeforeWithdrawalForm,
				meta: { stage: null },
			},
			{
				path: 'withdrawal/:walletId',
				name: 'withdrawal',
				component: Withdrawal,
				meta: { requiresAuth: true, stage: 'BeforeWithdrawal' },
				children: [
					{
						path: '/',
						name: 'withdrawal-select',
						component: WithdrawalSelect,
						meta: { checkForWithdrawal: 'message' },
					},
					{
						path: 'bitstamp',
						name: 'withdrawal-bitstamp',
						component: WithdrawalBitstamp,
						meta: { checkForWithdrawal: 'redirect-back' },
					},
					{
						path: 'trustology',
						name: 'withdrawal-trustology',
						component: WithdrawalTrustology,
						meta: { checkForWithdrawal: 'redirect-back' },
					},
					{
						path: 'external_link',
						name: 'withdrawal-external-link',
						component: ExternalLinkWithdrawal,
					},
					{
						path: 'generic_withdrawal',
						name: 'withdrawal-other',
						component: WithdrawalOther,
						meta: { checkForWithdrawal: 'redirect-back' },
					},
					{
						path: 'confirmation',
						name: 'withdrawal-confirmation',
						component: WithdrawalConfirmation,
						meta: { requiresAuth: true },
						props: true,
					},
				],
			},
		],
	},
	{
		path: '/documents/upload',
		name: 'kyc-documents',
		component: KYCDocuments,
		meta: { requiresAuth: true },
	},
	{
		path: '/documents/upload/confirmation',
		name: 'kyc-upload-confirmation',
		component: KYCUploadConfirmation,
		meta: { requiresAuth: true },
	},
	{
		path: '/documents/unverified',
		name: 'kyc-documents-unverified',
		component: KYCNotVerified,
		meta: { requiresAuth: true },
	},
	{
		path: '/identity-verification',
		name: 'identity-verification',
		component: IdentityVerification,
		meta: { requiresAuth: true },
	},
	{
		path: '/reset-password',
		name: 'reset-password',
		component: ResetPassword,
		props: true,
		meta: { requiresAuth: false },
	},
	{
		path: '/profile',
		name: 'profile-info',
		component: ProfileInfo,
		props: true,
		meta: { requiresAuth: true },
	},
	{
		path: '/profile/change-password',
		name: 'change-password',
		component: ChangePassword,
		props: true,
		meta: { requiresAuth: true },
	},
	{
		path: '/transfer',
		name: 'transfer',
		component: Transfer,
		props: true,
		meta: { requiresAuth: true },
	},
	{
		path: '/create-wallet',
		name: 'create-wallet',
		component: CreateWallet,
		props: true,
		meta: { requiresAuth: true },
	},
	{
		path: '/wallet-created',
		name: 'wallet-created',
		component: WalletCreated,
		props: true,
		meta: { requiresAuth: true },
	},
	{
		path: '/oauth/authorize',
		name: 'oauth-authorize',
		component: OAuthAuthorize,
		meta: { requiresAuth: true, redirectBackAfterLogout: true },
	},
	{
		path: '*',
		name: 'not-found',
		component: NotFound,
		meta: { requiresAuth: false },
	},
];

export const router = new VueRouter({
	mode: 'history',
	routes,
});

if (process.env.VUE_APP_GA_ID) {
	Vue.use(VueAnalytics, {
		id: process.env.VUE_APP_GA_ID,
		router: router,
	});
}

if (process.env.VUE_APP_GTM_ID) {
	Vue.use(VueGtm, {
		id: process.env.VUE_APP_GTM_ID,
		debug: false,
		vueRouter: router,
	});
}

if (process.env.VUE_APP_HOTJAR_ID) {
	Vue.use(Hotjar, {
		id: process.env.VUE_APP_HOTJAR_ID,
	});
}

// Store token in local storage if provided
router.beforeEach(async (to, from, next) => {
	let { query } = to;
	if (query.token) {
		store.dispatch('setAuthToken', query.token);
		if (query.redirect_uri) {
			window.location = query.redirect_uri;
		}
		await store.dispatch('fetchUser');
		query = { ...query, token: undefined };
		next({ ...to, query });
	}

	next();
});

// Router Guard
router.beforeEach((to, from, next) => {
	const isAuthenticated = Boolean(store.getters.getAuthToken);
	if (to.matched.some((record) => record.meta.requiresAuth) && !isAuthenticated) {
		next({
			path: '/login',
			query: { redirect: to.fullPath },
		});
	} else if (to.matched.some((record) => record.meta.notAuthenticatedOnly) && isAuthenticated) {
		Vue.$toast.error(i18n.t('errors.you-are-already-logged-in').toString());
		next({
			path: '/',
		});
	} else {
		next(); // make sure to always call next()!
	}
});

router.beforeEach(async (to, from, next) => {
	if (to.matched.some((record) => record.meta.stage) && to.meta.stage !== null) {
		const stage = to.meta.stage ?? to.matched.find((record) => record.meta.stage).meta.stage;
		const { steps } = await apiClient.getPendingStepsForStage(stage);
		if (steps.length < 1) {
			next();
		} else {
			router.push({
				path: await getPathForNextOutstandingOnboardingStep(steps),
				query: { embedded: to.query.embedded, hideBack: to.query.hideBack, redirect: to.fullPath },
			});
		}
	} else {
		next(); // make sure to always call next()!
	}
});

// Set locale based on query param or local storage
router.beforeEach((to, from, next) => {
	const langFromQuery = to.query.lang;

	if (langFromQuery) {
		store.dispatch('setUserLanguage', langFromQuery);
	}

	next();
});

// Store ref code in local storage if provided and remove from URL
router.beforeEach((to, from, next) => {
	const { ref } = to.query;
	const query = { ...to.query, ref: undefined };
	if (ref) {
		localStorage.setItem('ref', ref);
		next({ path: to.path, query, replace: true });
	} else {
		next();
	}
});

router.beforeEach((to, from, next) => {
	if (from.query.embedded && !to.query.embedded) {
		router.push({ name: to.name, query: { ...from.query, ...to.query, hideBack: false } });
	} else {
		next();
	}
});

router.afterEach(() => {
	PosthogUtil.captureEvent('$pageview');
});

router.afterEach(async (to, from, next) => {
	const isAuthenticated = Boolean(store.getters.getAuthToken);
	if (isAuthenticated) {
		await apiClient.activityReport('PAGEVISIT', to.path);
	}
});

new Vue({
	i18n,
	router,
	store,
	render: (h) => h(App),
}).$mount('#app');
