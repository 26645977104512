<template>
	<div class="wrapper">
		<Loader v-if="isAnyLoading || pendingWalletAutoSelect" fullscreen />
		<PageLayout v-else :title="$t('oauth.title')" :subtitle="$t('oauth.subtitle')" hide-back-button>
			<WalletPicker
				v-if="wallets.data && !isAnyLoading && !pendingWalletAutoSelect"
				:user="getUser"
				:wallets="wallets.data"
				@click="handleWalletSelected"
			/>
			<div class="error-wrapper">
				<h2 v-if="isAnyError" class="error">
					{{ anyError }}
				</h2>
			</div>
			<ErrorCard :error="getUserError" />
		</PageLayout>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import apiClient from '@/api';
import Loader from '@/shared/loader/Loader';
import ErrorCard from '@/shared/error-card/ErrorCard';
import PageLayout from '@/components/page-layout/PageLayout';
import WalletPicker from '@/components/wallet-picker/WalletPicker';
import * as Sentry from '@sentry/vue';

export default {
	name: 'OAuthAuthorize',
	components: { WalletPicker, PageLayout, Loader, ErrorCard },
	data() {
		return {
			oauth: {
				loading: false,
				error: null,
			},
			wallets: {
				loading: false,
				data: null,
				error: null,
			},
			pendingWalletAutoSelect: false,
		};
	},
	computed: {
		...mapGetters(['getUser', 'getUserError']),
		isAnyLoading() {
			return this.oauth.loading || this.wallets.loading;
		},
		isAnyError() {
			return this.oauth.error || this.wallets.error;
		},
		anyError() {
			return this.oauth.error ?? this.wallets.error;
		},
	},
	watch: {
		'wallets.data': function (newValue, oldValue) {
			const wantedWalletId = this.$route.query.wallet_id;
			if (oldValue === null && newValue !== null && wantedWalletId) {
				const wantedWallet = newValue.find((wallet) => wallet.username === wantedWalletId);
				if (wantedWallet) {
					this.handleWalletSelected(wantedWallet.id);
				} else {
					Sentry.captureMessage(
						`Attempted to login with wallet_id: ${wantedWalletId} which wasn't in users (${this.getUser.id}) list of wallets`
					);
					this.pendingWalletAutoSelect = false;
				}
			}
			if (oldValue === null && newValue !== null && newValue.length === 1) {
				this.handleWalletSelected(newValue[0].id);
			}
		},
	},
	created() {
		this.getUsersWallets();
		this.checkForWantedWalletId();
	},
	methods: {
		async getUsersWallets() {
			try {
				this.wallets.loading = true;
				const { wallets } = await apiClient.getUsersWallets();
				this.wallets.data = wallets;
			} catch (e) {
				this.wallets.error = e.message;
				this.pendingWalletAutoSelect = false;
			} finally {
				this.wallets.loading = false;
			}
		},
		checkForWantedWalletId() {
			if (this.$route.query.wallet_id) {
				this.pendingWalletAutoSelect = true;
			}
		},
		async handleWalletSelected(walletId) {
			try {
				this.oauth.loading = true;
				this.oauth.error = null;
				const { redirect } = await apiClient.getOAuthCode(this.$route.query, walletId);
				if (redirect) {
					window.location = redirect;
				} else {
					this.oauth.loading = false;
				}
			} catch (e) {
				this.oauth.error = this.extractOAuthErrorMessage(e);
				this.oauth.loading = false;
			}
		},
		extractOAuthErrorMessage(error) {
			if (error.response) {
				if (error.response.data?.error === 'invalid_scope') {
					return this.$t('errors.oauth-lacks-required-role');
				}
				console.log('axios error', error.response);
			}
			return this.$t('errors.oauth-failed');
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/variables';

.wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.error-wrapper {
	margin-top: 1rem;

	.error {
		color: $torch-red;
		text-align: center;
	}
}
</style>
