import ar from './ar.json';
import tz from './sw.json';
import ke from './sw.json';
import en from './en.json';
import de from './de.json';
import es from './es.json';
import ru from './ru.json';
import ur from './ur.json';
import pt from './ptbr.json';
import tr from './tr.json';
import ms from './ms.json';
import fr from './fr.json';
import hi from './hi.json';
import it from './it.json';
import ja from './ja.json';
import vi from './vi.json';
import km from './km.json';
import zh from './zh.json';

export const defaultLocale = 'en';

export const languages = {
	en,
	de,
	es,
	pt,
	tr,
	ru,
	ur,
	tz,
	ke,
	ms,
	fr,
	hi,
	it,
	ja,
	km,
	vi,
	zh,
	ar,
};

export const availableLanguages = [
	'en',
	'es',
	'fr',
	'pt',
	'ar',
	'tr',
	'ru',
	'it',
	'de',
	'hi',
	'ur',
	'ke',
	'ms',
	'ja',
	'vi',
	'km',
	'zh',
];
