var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-layout"},[(!_vm.hideBackButton)?_c('div',{staticClass:"mobile-back-wrapper"},[_c('BackButton')],1):_vm._e(),_c('div',{staticClass:"content-wrapper",class:{
			[_vm.wrapperClass]: _vm.wrapperClass,
			'content-wrapper--small': _vm.smallWidth,
			'content-wrapper--medium': _vm.mediumWidth,
			'content-wrapper--large': _vm.largeWidth,
			'content-wrapper--x-large': _vm.xLargeWidth,
		}},[_c('div',{staticClass:"heading-wrapper"},[(!_vm.hideBackButton)?_c('div',{staticClass:"desktop-back-wrapper"},[_c('BackButton')],1):_vm._e(),(_vm.title)?_c('PageTitle',[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.subtitle || _vm.$slots.subtitle)?_c('PageSubtitle',[_vm._t("subtitle",function(){return [_vm._v(" "+_vm._s(_vm.subtitle)+" ")]})],2):_vm._e()],1),_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }