<template>
	<p class="page-subtitle">
		<slot />
	</p>
</template>

<script>
export default {
	name: 'PageSubtitle',
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/variables';

.page-subtitle {
	font-size: $label;
	line-height: 1.5;
	color: rgba(255, 255, 255, 0.6);
	margin: 0;
}
</style>
