<template>
	<div id="sumsub-websdk-container" />
</template>

<script>
import snsWebSdk from '@sumsub/websdk';
import { mapGetters } from 'vuex';
import * as Sentry from '@sentry/vue';
import apiClient from '@/api';

export default {
	name: 'IdentityVerification',
	props: {
		accessToken: String,
	},
	async mounted() {
		this.launchWebSdk(this.accessToken);
	},
	computed: {
		...mapGetters(['getUser']),
	},
	methods: {
		handleMessageListener(type, payload) {
			if (type === 'idCheck.onReady') {
				this.$emit('loaded');
			}
			if (type === 'idCheck.applicantStatus' && ['GREEN', 'YELLOW'].includes(payload.reviewResult?.reviewAnswer)) {
				this.$emit('verified');
			}
		},
		launchWebSdk(accessToken) {
			const { email, mobilenumber } = this.getUser;
			const snsWebSdkInstance = snsWebSdk
				.init(
					accessToken,
					() =>
						// Fetching new token on expiration
						apiClient.getSumSubTokenForUser
				)
				.withConf({
					lang: this.$root.$i18n.locale,
					email,
					phone: mobilenumber,
				})
				.on('onError', (error) => {
					console.error('WebSDK onError', error);
					Sentry.captureException(error);
				})
				.onMessage(this.handleMessageListener)
				.build();

			snsWebSdkInstance.launch('#sumsub-websdk-container');
		},
	},
};
</script>
