import Vue from 'vue';

Vue.mixin({
	data() {
		return {
			brandLogo: process.env.VUE_APP_BRAND_LOGO,
			brandName: process.env.VUE_APP_BRAND_NAME,
			backendUrl: process.env.VUE_APP_URL,
		};
	},
});
