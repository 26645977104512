import { availableLanguages } from '@/i18n';
import apiClient from '@/api';

const state = {
	userLanguage: 'en',
	countries: [],
	greyLabelLogos: {
		data: null,
		isLoading: false,
	},
};

const getters = {
	getUserLanguage: (state) => state.userLanguage,
	getCountries: (state) => state.countries,
	getGreyLabelLogos: (state) => state.greyLabelLogos,
};

const mutations = {
	setUserLanguage: (state, language) => {
		state.userLanguage = language;
	},
	setCountries: (state, countries) => {
		state.countries = countries;
	},

	requestGreyLabelLogo: (state) => {
		state.greyLabelLogos = {
			data: null,
			isLoading: true,
		};
	},

	handleGreyLabelLogoError: (state) => {
		state.greyLabelLogos = {
			data: null,
			isLoading: false,
		};
	},

	setGreyLabelLogos: (state, logos) => {
		state.greyLabelLogos = {
			data: logos,
			isLoading: false,
		};
	},
};

const actions = {
	async setUserLanguage({ commit, state, rootGetters, dispatch }, language, skipUserUpdate = false) {
		if (
			state.userLanguage !== language ||
			(rootGetters.getUser !== null && rootGetters.getUser.communicationLanguage !== language)
		) {
			if (availableLanguages.includes(language)) {
				commit('setUserLanguage', language);
				if (rootGetters.isLoggedIn && !skipUserUpdate) {
					dispatch('updateUser', {
						communicationLanguage: language,
					});
				}
			} else {
				console.error(`Tried to set unsupported language ${language}`);
			}
		}
	},
	async fetchCountries({ commit }) {
		try {
			const { countries } = await apiClient.fetchCountries();
			commit('setCountries', countries);
		} catch (e) {
			console.error(e);
		}
	},

	async requestGreyLabelLogos({ commit }, wtreferrer) {
		commit('requestGreyLabelLogo');
		try {
			const { logos } = await apiClient.getGreyLabelLogo(wtreferrer);
			commit('setGreyLabelLogos', logos);
		} catch (e) {
			commit('handleGreyLabelLogoError');
			console.error(e);
		}
	},
};

export default {
	state,
	mutations,
	actions,
	getters,
};
