import apiClient from '@/api';
import PosthogUtil from '@/utils/posthog';

const state = {
	loading: false,
	success: false,
	error: null,
	data: null,
	supportedFeatures: [],
};

const getters = {
	getUser: (state) => state.data,
	getUserError: (state) => state.error,
	getSupportedFeatures: (state) => state.supportedFeatures,
	getUserFetchStatus: (state) => {
		const { loading, success, error } = state;
		return { loading, success, error };
	},
	getUsername: (state) => state.data?.name,
	getEmail: (state) => state.data?.email,
};

const mutations = {
	fetchUserSuccess: (state, user) => {
		state.loading = false;
		state.success = true;
		state.error = null;
		state.data = user;
	},
	fetchUserError: (state, error) => {
		state.loading = false;
		state.success = false;
		state.error = error;
		state.data = null;
	},
	fetchUserLoading: (state) => {
		state.loading = true;
		state.success = false;
		state.error = null;
		state.data = null;
	},
	setUser: (state, user) => {
		state.data = user;
	},
	setSupportedFeatures: (state, supportedFeatures) => {
		state.supportedFeatures = supportedFeatures;
	},
};

const actions = {
	setUser({ commit }, user) {
		commit('setUser', user);
	},
	async fetchUser({ commit, dispatch }) {
		try {
			commit('fetchUserLoading');
			const response = await apiClient.fetchUserProfile();
			dispatch('setUserLanguage', response.user.communicationLanguage, true);
			commit('setSupportedFeatures', response.supportedFeatures);
			commit('fetchUserSuccess', response.user);
			PosthogUtil.identifyUser(response.user.id);
		} catch (e) {
			commit('fetchUserError', e);
		}
	},
	async refetchUser({ commit, dispatch }) {
		try {
			const response = await apiClient.fetchUserProfile();
			dispatch('setUserLanguage', response.user.communicationLanguage, true);
			commit('setSupportedFeatures', response.supportedFeatures);
			commit('fetchUserSuccess', response.user);
		} catch (e) {
			console.error('Failed to fetch user');
		}
	},
	async updateUser({ commit }, userParams) {
		const { user } = await apiClient.updateUserProfile(userParams);
		commit('setUser', user);
	},
};

export default {
	state,
	mutations,
	actions,
	getters,
};
