<template>
	<button :type="type" class="button" :class="computedClasses" :disabled="disabled" @click="handleClick">
		<Spinner v-if="loading" small />
		<img v-else-if="success" class="check-icon" src="../../assets/icons/check-white.svg" alt="check" />
		<slot v-else />
	</button>
</template>

<script>
import Spinner from '@/shared/spinner/Spinner';

export default {
	name: 'CButton',
	components: {
		Spinner,
	},
	props: {
		type: {
			type: String,
			default: 'button',
		},
		fullWidth: {
			type: Boolean,
			default: false,
		},
		primary: {
			type: Boolean,
			default: false,
		},
		secondary: {
			type: Boolean,
			default: false,
		},
		tertiary: {
			type: Boolean,
			default: false,
		},
		danger: {
			type: Boolean,
			default: false,
		},
		dangerPink: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		success: {
			type: Boolean,
			default: false,
		},
		dark: {
			type: Boolean,
			default: false,
		},
		darkRounded: {
			type: Boolean,
			default: false,
		},
		condensed: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		computedClasses() {
			return {
				'full-width': this.fullWidth,
				'primary-button': this.primary,
				'danger-button': this.danger,
				'danger-pink-button': this.dangerPink,
				'secondary-button': this.secondary,
				'tertiary-button': this.tertiary,
				'dark-button': this.dark,
				'dark-rounded-button': this.darkRounded,
				'button--condensed': this.condensed,
			};
		},
	},
	methods: {
		handleClick(e) {
			this.$emit('click', e);
		},
	},
};
</script>

<style lang="scss">
.check-icon {
	width: 1.3rem;
	height: 1.3rem;
}
</style>
