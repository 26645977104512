<template>
	<h1
		class="page-title"
		:class="{
			'page-title--big': big,
		}"
	>
		<slot />
	</h1>
</template>

<script>
export default {
	name: 'PageTitle',
	props: {
		big: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/variables';

.page-title {
	margin: 0 0 0;
	font-weight: bold;
	font-size: $heading;
	line-height: 1.3;
	color: $white;

	&--big {
		margin: 0 0 2rem;
		text-align: start;
		font-size: $heading-large;
	}
}
</style>
