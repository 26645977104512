<template>
	<div id="app">
		<div
			class="cashier-app dark-theme"
			:class="{
				embedded: isEmbedded,
				[`brand-${brand}`]: true,
			}"
		>
			<div v-if="error" class="router-wrapper">
				<div class="app-wrapper">
					<ErrorCard :error="error" />
				</div>
			</div>

			<transition v-else-if="getUserFetchStatus.loading" mode="out-in">
				<Loader />
			</transition>
			<transition v-else mode="out-in">
				<div class="router-wrapper">
					<HeaderBar v-if="!isEmbedded" :floating="shouldUseFloatingHeader" />
					<div class="app-wrapper">
						<router-view />
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Loader from '@/shared/loader/Loader';
import HeaderBar from '@/components/header-bar/HeaderBar';
import ErrorCard from '@/shared/error-card/ErrorCard';

export default {
	name: 'App',
	components: {
		HeaderBar,
		Loader,
		ErrorCard,
	},
	data() {
		return {
			brand: process.env.VUE_APP_BRAND,
			// set this to an error object to show a full screen error card instead
			// of the actual content.
			error: null,
		};
	},
	computed: {
		...mapGetters(['isLoggedIn', 'isEmbedded', 'getAuthToken', 'getUserLanguage', 'getUserFetchStatus']),
		shouldUseFloatingHeader() {
			return Boolean(this.$route.meta.useFloatingHeader);
		},
	},
	watch: {
		$route(newRoute) {
			this.setIsEmbedded(newRoute.query.embedded === 'true');
		},
		getUserLanguage(userLanguage) {
			this.changeAppLocale(userLanguage);
		},
		isEmbedded: {
			immediate: true,
			handler(isEmbedded) {
				if (isEmbedded) {
					document.body.classList.add('embedded');
				} else {
					document.body.classList.remove('embedded');
				}
			},
		},
	},
	methods: {
		...mapActions(['setIsEmbedded', 'fetchCountries', 'fetchUser', 'requestGreyLabelLogos']),
		changeAppLocale(locale) {
			this.$root.$i18n.locale = locale;
		},
	},
  mounted() {
    this.$router.onReady(() => {
      const wtreferrer = this.$route.query.fref;

      if (wtreferrer && this.getAuthToken === null) {
        this.requestGreyLabelLogos(wtreferrer);
      } else if (this.getAuthToken !== null) {
        this.fetchUser();
        this.requestGreyLabelLogos();
      }
      this.fetchCountries();
      });
  },
	errorCaptured(err) {
		this.error = err;
	},

	beforeMount() {
		document.title = `Customer Portal - ${this.brandName}`;
	},
};
</script>

<style lang="scss">
@import 'assets/style/global';
@import 'assets/style/cashier-form';
@import 'assets/style/button';
@import 'assets/style/language-dropdown';
@import 'assets/style/payment-method';
@import 'assets/style/logo';
@import 'assets/style/brand-overrides';

:root {
	color-scheme: dark;
}

.router-wrapper {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.app-wrapper {
	display: flex;
	flex: 1;
	flex-direction: column;
}

@include max-screen($md) {
	.top-bar {
		margin-top: 10px;
		margin-right: $mobile-horizontal-margin;
		margin-left: $mobile-horizontal-margin;
	}
}

.embedded {
	padding: 0;
	margin: 0;
	background-color: transparent !important;
}
</style>
