import apiClient from '@/api';

const state = {
	availableMethods: null,
	loading: false,
	success: false,
	error: null,
};

const getters = {
	availableMethods: (state) => {
		return state.availableMethods
			? state.availableMethods.map((m) => ({
					id: m.id,
					name: m.name,
					icon: m.icon ? require(`../../assets/payment-icons/${m.icon}`) : null,
					min: m.min,
					max: m.max,
					depositCurrency: m.depositCurrency,
					predefinedDepositAmounts: m.predefinedDepositAmounts,
			  }))
			: null;
	},
	getAvailableMethodsFetchStatus: (state) => {
		const { loading, success, error } = state;
		return { loading, success, error };
	},
	getCurrentMethod: (state) => (methodName) => {
		return state.availableMethods.find((value) => value.id === methodName);
	},
};

const actions = {
	async fetchAvailablePaymentMethods({ commit }) {
		try {
			commit('fetchAvailablePaymentMethodsLoading');
			const response = await apiClient.getAvailableDepositMethods();
			commit('fetchAvailablePaymentMethodsSuccess', response.data.methods);
		} catch (e) {
			commit('fetchAvailablePaymentMethodsError', e);
		}
	},
};

const mutations = {
	fetchAvailablePaymentMethodsLoading: (state) => {
		state.success = false;
		state.loading = true;
		state.error = null;
		state.availableMethods = null;
	},
	fetchAvailablePaymentMethodsSuccess: (state, availableMethods) => {
		state.success = true;
		state.loading = false;
		state.error = null;
		state.availableMethods = availableMethods;
	},
	fetchAvailablePaymentMethodsError: (state, error) => {
		state.success = false;
		state.loading = false;
		state.error = error;
		state.availableMethods = null;
	},
};

export default {
	state,
	mutations,
	actions,
	getters,
};
