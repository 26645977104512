<template>
	<span class="spinner-border" :class="{ 'spinner-border-sm': small }" role="status" aria-hidden="true" />
</template>
<script>
export default {
	name: 'Spinner',
	props: {
		small: Boolean,
	},
};
</script>

<style scoped>
@keyframes spinner-border {
	to {
		transform: rotate(360deg);
	}
}

.spinner-border {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: text-bottom;
	border: 0.25em solid currentColor;
	border-right-color: transparent;
	border-radius: 50%;
	animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
	width: 1rem;
	height: 1rem;
	border-width: 0.2em;
}
</style>
