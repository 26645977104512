<template>
	<div v-if="!shouldHide" class="back-button" @click="$router.go(-1)">
		<img src="../../assets/back.svg" alt="back-icon" class="back-button__icon" />
	</div>
</template>

<script>
export default {
	name: 'BackButton',
	data() {
		return {
			hideBack: false,
		};
	},
	computed: {
		shouldHide() {
			return this.$route.fullPath === '/' || this.hideBack;
		},
	},
	watch: {
		$route() {
			this.handleHideBackQueryParam();
		},
	},
	beforeMount() {
		this.handleHideBackQueryParam();
	},
	methods: {
		handleHideBackQueryParam() {
			this.hideBack = this.$route.query.hideBack === 'true';
		},
	},
};
</script>

<style lang="scss" scoped>
// back button module
.back-button {
	cursor: pointer;
	height: 42px;
	min-height: 42px;
	width: 42px;
	min-width: 42px;
	background: linear-gradient(134.22deg, #565a5c -63.52%, #2a2e31 74.66%), #2a2e31;
	border: 1px solid rgba(255, 255, 255, 0.1);
	box-sizing: border-box;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;

	&__icon {
		width: 16px;
	}
}
</style>
