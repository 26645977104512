<template>
	<div class="wallet-picker">
		<a v-for="wallet in wallets" :key="wallet.id" class="wallet-picker__item" @click="$emit('click', wallet.id)">
			<div class="avatar">
				<img :src="user.avatarUrl" alt="avatar" />
			</div>
			<div class="names">
				<span class="user-name">
					{{ user.name }}
				</span>
				<span class="wallet-name">
					{{ wallet.username }}
				</span>
			</div>
			<div v-if="wallet.primary" class="wallet-badge primary">
				<span class="label">
					{{ $t('wallets.type.primary') }}
				</span>
			</div>
			<div v-if="wallet.type === 'COPYTRADING'" class="wallet-badge secondary">
				<span class="label">
					{{ $t('wallets.type.copytrading') }}
				</span>
			</div>
			<div class="arrow-icon__wrapper">
				<img class="arrow-icon" alt="arrow-right" :src="require('../../assets/icons/arrow-right.svg')" />
			</div>
		</a>
	</div>
</template>

<script>
export default {
	name: 'WalletPicker',
	props: {
		user: {
			type: Object,
		},
		wallets: {
			type: Array,
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/_variables';

.wallet-picker {
	max-width: 400px;
	margin: auto;
	border: 1px solid $shark-black;
	border-radius: 8px;
	overflow: hidden;

	&__item {
		display: flex;
		background-color: $bunker-black;
		align-items: center;
		padding: 0.5rem 1rem;

		transition: background-color 0.2s ease-out;

		.arrow-icon {
			transform: translateX(-20px);
			opacity: 0;
			transition: all 0.4s ease-out;
		}

		&:hover {
			background-color: $cashier-dark;
			.arrow-icon {
				opacity: 1;
				transform: translateX(0);
			}
		}
	}

	.avatar {
		width: 2rem;
		height: 2rem;
		margin-right: 1rem;

		img {
			width: 100%;
			border-radius: 50%;
		}
	}

	.names {
		display: flex;
		flex-direction: column;
		text-align: left;
		flex: 2;

		.user-name {
			font-size: $body;
			font-weight: $bold;
			color: white;
		}

		.wallet-name {
			font-size: $caption;
			color: white;
			opacity: 0.5;
		}
	}

	.wallet-badge {
		padding: 0.2rem 0.3rem;
		border-radius: 3px;

		.label {
			font-size: $caption;
			display: block;
			color: white;
		}

		&.primary {
			border: 1px solid $mantis-green;
		}

		&.secondary {
			border: 1px solid $blue;
		}
	}

	.arrow-icon__wrapper {
		flex: 1;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		position: relative;
	}
}
</style>
