<template>
	<img class="app-logo" :alt="brandName" :src="require('../../assets/logo/' + brandLogo)" />
</template>

<script>
export default {
	name: 'BrandLogo',
};
</script>
<style lang="scss">
.app-logo {
	width: 100%;
	height: auto;
	max-height: 41px;
	object-fit: contain;
}
</style>
