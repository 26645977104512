<template>
	<div v-if="!isEmbedded && checkAvailableLanguages()" class="language-selector">
		<multiselect
			v-model="value"
			:options="availableLanguages"
			:option-height="104"
			:show-labels="false"
			:searchable="false"
			:close-on-select="true"
			:allow-empty="false"
			@input="changeLang"
		>
			<template slot="singleLabel" slot-scope="props">
				<img class="option__image" :src="require('../../assets/flags/' + props.option + '.png')" />
			</template>
			<template slot="option" slot-scope="props">
				<img class="option__image" :src="require('../../assets/flags/' + props.option + '.png')" />
			</template>
		</multiselect>
	</div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { mapActions, mapGetters } from 'vuex';
import { availableLanguages } from '../../i18n';

export default {
	name: 'LanguageSelector',
	components: { Multiselect },
	data() {
		return {
			availableLanguages,
			value: '',
		};
	},
	created() {
		this.value = this.getUserLanguage;
	},
	computed: {
		...mapGetters(['getUserLanguage', 'isEmbedded']),
	},
	watch: {
		getUserLanguage(lang) {
			this.value = lang;
		},
	},
	methods: {
		...mapActions(['setUserLanguage']),
		async changeLang(lang) {
			if (lang) {
				await this.setUserLanguage(lang);
			}
		},
		checkAvailableLanguages() {
			return this.availableLanguages?.length > 0;
		},
	},
};
</script>

<style scoped lang="scss">
.language-selector {
	height: 60px;
	z-index: 1000;
}

::v-deep .multiselect__content-wrapper {
	overflow-x: hidden;
	overflow-y: scroll;
}
</style>
