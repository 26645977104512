<template>
	<img class="affiliate-logo" :alt="brandName" :src="logo" />
</template>

<script>
export default {
	name: 'AffiliateLogo',
	props: {
		logo: String,
	},
};
</script>
<style lang="scss">
@import 'src/assets/style/_variables';
@import 'src/assets/style/mixin';

.affiliate-logo {
	width: 100%;
	height: auto;
	object-fit: contain;
	max-height: 48px;

	@include min-screen($md) {
		max-height: 62px;
	}
}
</style>
