import apiClient from '../api';

// This function returns the path to the highest priority pending onboarding step, returning only one path at a time
export const getPathForNextOutstandingOnboardingStep = async (pendingSteps) => {
	if (pendingSteps.length < 1) {
		return null;
	}
	return getPathForOutstandingOnboardingStep(pendingSteps[0].step);
};

const getPathForOutstandingOnboardingStep = async (pendingStep) => {
	const sumsubEnabled = await apiClient.isSumsubEnabled();
	switch (pendingStep) {
		case 'CompleteAccountBeforeDepositForm':
			return '/payments/deposit/form';
		case 'CompleteAccountBeforeWithdrawForm':
			return '/payments/withdrawals/form';
		case 'EmailConfirmation':
			return '/confirm-email';
		case 'PhoneConfirmation':
			return '/confirm-phone';
		case 'KYCManual':
			return '/documents/upload';
		case 'KYC':
			if (sumsubEnabled) {
				return '/identity-verification';
			} else {
				return '/documents/upload';
			}
		case 'DisclaimerBeforeDeposit':
			return '/disclaimer';
		default:
			return null;
	}
};
