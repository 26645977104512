<template>
	<div class="lds-ring" :class="{ ['lds-ring--fullscreen']: fullscreen }">
		<div />
		<div />
		<div />
		<div />
	</div>
</template>

<script>
export default {
	name: 'Loader',
	props: {
		fullscreen: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style scoped lang="scss">
.lds-ring {
	padding: 2rem;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	&--fullscreen {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 64px;
		height: 64px;
		margin: 8px;
		border: 8px solid #fff;
		border-radius: 50%;
		animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: #fff transparent transparent transparent;
	}
	div:nth-child(1) {
		animation-delay: -0.45s;
	}
	div:nth-child(2) {
		animation-delay: -0.3s;
	}
	div:nth-child(3) {
		animation-delay: -0.15s;
	}
}

@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
