<template>
	<div class="page-layout">
		<div v-if="!hideBackButton" class="mobile-back-wrapper">
			<BackButton />
		</div>
		<div
			class="content-wrapper"
			:class="{
				[wrapperClass]: wrapperClass,
				'content-wrapper--small': smallWidth,
				'content-wrapper--medium': mediumWidth,
				'content-wrapper--large': largeWidth,
				'content-wrapper--x-large': xLargeWidth,
			}"
		>
			<div class="heading-wrapper">
				<div v-if="!hideBackButton" class="desktop-back-wrapper">
					<BackButton />
				</div>

				<PageTitle v-if="title">
					{{ title }}
				</PageTitle>

				<PageSubtitle v-if="subtitle || $slots.subtitle">
					<slot name="subtitle">
						{{ subtitle }}
					</slot>
				</PageSubtitle>
			</div>

			<slot />
		</div>
	</div>
</template>

<script>
import BackButton from '@/components/back-button/BackButton';
import PageTitle from '@/components/page-title/PageTitle';
import PageSubtitle from '@/components/page-subtitle/PageSubtitle';

export default {
	name: 'PageLayout',
	components: { PageSubtitle, PageTitle, BackButton },
	props: {
		title: {
			type: String,
			default: null,
		},
		subtitle: {
			type: String,
			default: null,
		},
		wrapperClass: {
			type: String,
			default: null,
		},
		smallWidth: {
			type: Boolean,
			default: false,
		},
		mediumWidth: {
			type: Boolean,
			default: false,
		},
		largeWidth: {
			type: Boolean,
			default: false,
		},
		xLargeWidth: {
			type: Boolean,
			default: false,
		},
		hideBackButton: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/variables';

.content-wrapper {
	max-width: $large-width;
	margin: 0 auto;
	padding: $desktop-horizontal-margin;

	@include max-screen($md) {
		padding: $mobile-horizontal-margin;
	}

	&--small {
		max-width: $small-width;
	}

	&--medium {
		max-width: $medium-width;
	}

	&--large {
		max-width: $large-width;
	}

	&--x-large {
		max-width: $xlarge-width;
	}
}

.mobile-back-wrapper {
	display: block;
	margin-top: $mobile-horizontal-margin;
	margin-left: $mobile-horizontal-margin;
	height: 42px;

	@include min-screen($md + 1) {
		display: none;
	}
}

.heading-wrapper {
	position: relative;
	margin-bottom: 1.2rem;

	@include min-screen($md + 1) {
		&::v-deep .page-title {
			line-height: 42px !important;
		}
	}
}

.desktop-back-wrapper {
	display: none;
	position: absolute;
	left: 0;

	@include min-screen($md + 1) {
		display: block;
	}
}

.embedded {
	.content-wrapper {
		padding: 0;
	}

	.mobile-back-wrapper {
		margin: 0 0 1rem;
	}
}
</style>
